@import '../../variables/colors';

.search-main .show {
  > .ct--search-show-topbar,
  > .topnav {
    width: 100%;
    height: 65px;
    padding: 0 25px;
    display: flex;
    justify-content: space-between;

    .col {
      display: flex;
      align-items: center;

      .back {
        margin-right: 20px;

        .icon {
          width: 10px;
          height: 19px;
        }
      }
    }

    .filter-quantity-topnav {
      display: flex;
      align-items: center;
      margin-right: 15px;

      .icon {
        width: 24px;
        height: 18px;
        margin-right: 15px;
      }
    }

    .price-topnav {
      text-align: right;
      // margin-right: 15px;
      .price {
        margin-bottom: 3px;
      }

      .price,
      .quantity {
        display: block;
      }

      .quantity {
        letter-spacing: 1px;
      }
    }

    .options-topnav {
      .title-filter {
        margin-bottom: 5px;
        text-transform: uppercase;
      }

      .choose-option {
        position: relative;
        display: flex;
        border: 1px solid #ccc;
        max-width: 200px;
        height: 30px;
        overflow: hidden;
        background: $white;
        margin-right: 10px;

        select {
          padding: 5px 50px 5px 20px;
          width: 100%;
          height: 100%;
          border: none;
          box-shadow: none;
          background: transparent;
          background-image: none;
          -webkit-appearance: none;
          position: relative;
          z-index: 5;
          // box-sizing:
          cursor: pointer;

          &:focus {
            outline: none;
          }
        }

        .cursor {
          display: flex;
          width: 50px;
          height: 100%;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          right: 0;
          pointer-events: none;

          .icon {
            width: 8px;
            height: 5px;
          }
        }
      }
    }

    .filter-slider-quantity {
      width: 155px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 15px;

      .headline {
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .wrapper-range-slider {
        margin-bottom: 0;
      }
    }
  }

  .pictures-slider-wrapper {
    position: relative;
    width: 100%;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 0;
    }

    &.full {
      &:before {
        padding-bottom: 44%;
      }
    }

    &.half {
      &:before {
        padding-bottom: 100%;
      }
    }

    .badge-corner {
      position: absolute;
      top: 0;
      left: 0;
      height: 50px;
      width: 70px;
      z-index: 18;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-top: 80px solid $light-duck;
        border-right: 80px solid transparent;
      }
      &.badge-rse {
        .svg-surplus {
          position: absolute;
          top: 9px;
          left: 9px;
          stroke: #fff;
          fill: #fff;
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  > .show-wrapper {
    padding: 10px 55px 20px;
    @extend .text-1;

    strong {
      @extend .rawline-bold;
    }

    > .headline-before {
      text-align: right;
      p {
        @extend .text-1;
        font-size: 12px;
        margin-top: -10px;
      }
    }

    > .headline {
      display: flex;
      justify-content: space-between;

      .ct--breadcrumb {
        margin: 0;

        .inner {
          padding: 10px 0;
        }
      }
    }
    .title-row-fiche {
      display: block;
      margin-bottom: 40px;
    }
    .product-title {
      margin-bottom: 30px;
    }
    .row-fiche {
      margin-bottom: 50px;
      ul.dotted-list {
        li {
          list-style-type: disc;
          margin-left: 20px;
        }
      }

      &.row-introduction {
        .product-facturation {
          margin-bottom: 12px;
        }
        .list-fiche {
          margin-bottom: 30px;
          li {
            margin-bottom: 5px;
            &:nth-child(odd) {
              width: 45%;
              margin-right: 5%;
            }
            @media screen and (max-width: 1190px) {
              width: 100%;
              &:nth-child(odd) {
                width: 100%;
              }
            }
          }
        }
      }
      &.row-in-short {
        .cols {
          justify-content: space-between;
        }
        .list-fiche {
          column-count: 2;
          display: initial;
          li {
            margin-bottom: 4px;
            width: initial;
          }
        }
        .recommendations {
          .icon {
            width: 60px;
            height: 60px;
            margin-right: 10px;
            margin-top: 10px;
            vertical-align: top;
          }
          ul {
            color: $light-duck;
            display: inline-block;
            font-size: 12px;
            border-left: 1px solid $light-duck;
            padding-left: 10px;
            margin: 0;
            min-height: 80px;
            li {
              list-style-type: disc;
              margin-left: 12px;
              &.recommendation-header {
                list-style-type: none;
                font-size: 14px;
                font-weight: bold;
                margin-left: 0;
              }
            }
          }
        }
      }
      &.row-item-results {
        .item-results {
          display: flex;
          flex-wrap: wrap;
          .ct.ct--item-result {
            width: calc(50% - 10px);
            &:nth-child(1) {
              margin-right: 20px;
            }
          }
        }
      }
      &.row-comments {
        .comment-results {
          .result-raw {
            margin-bottom: 25px;
            .about {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 5px;
              .notes {
                display: flex;
                align-items: center;
                li {
                  margin-right: 3px;
                  .icon {
                    width: 15px;
                    height: 13px;
                    fill: $star-off;
                  }
                  $num: 5;
                }
                @for $i from 1 through 5 {
                  &.notes-#{$i} {
                    li {
                      &:nth-child(-n + #{$i}) {
                        .icon {
                          fill: $star-on;
                        }
                      }
                    }
                  }
                }
              }
            }
            .images {
              margin-top: 10px;
              > a {
                margin-right: 10px;
              }
            }
          }

          .show-more-comments-wrapper {
            text-align: left;

            .show-more-comments {
              display: inline-block;
              height: 40px;
              margin-top: 10px;
            }
          }

          .more-comments {
            height: 0;
            overflow: hidden;
            transition: height 0.35s ease-out;
          }
        }
      }

      &.row-menu {
        margin-bottom: 70px;

        .title-row-menu {
          margin-bottom: 20px;
        }

        .content-row-menu {
          margin-bottom: 20px;
        }

        .about-menu {
          display: flex;
          flex-wrap: wrap;

          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .icon {
              width: 29px;
              height: 30px;
              margin-bottom: 15px;
            }
          }
        }
      }

      &.row-equipment {
        > section {
          margin-bottom: 30px;

          h3 {
            @extend .title-2;
            margin-bottom: 20px;
            padding: 0 2px 15px;
            border-bottom: 1px solid $black;
          }

          > section {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;

            ul {
              @extend .c4;
              padding-right: 40px;

              li {
                line-height: 1.35;
                margin-bottom: 3px;

                &.title {
                  font-weight: bold;
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }

    .cols {
      display: flex;

      .list-fiche {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        li {
          width: 48%;
        }
      }

      .ideal,
      .access {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        li {
          width: 120px;
          text-align: center;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 30px;

          .icon {
            width: 46px;
            height: 43px;
            margin-bottom: 10px;
          }

          span {
            display: block;
            text-align: center;
            line-height: 1.2;
          }
        }
      }

      &.public-transports-wrapper {
        .title-row-fiche {
          margin-bottom: 20px;
          font-size: 2rem;
        }
      }

      .subway-station-list {
        padding-left: 10px;

        li {
          line-height: 1.5;

          .icon {
            position: relative;
            top: 2px;
            margin-right: 1px;
          }

          .duration {
            color: #818181;
            font-size: 1.35rem;
          }
        }
      }

      &.cols-double {
        .col {
          &:nth-child(1) {
            padding-right: 50px;
          }

          .headline {
            .ct--breadcrumb {
              .inner {
                padding: 10px 0 0;
              }
            }
          }
        }
      }
    }
    .ct.ct--button.expandable-description-trigger {
      height: 40px;
    }
  }

  #comments-anchor-js {
    display: flex;
    align-items: center;
    color: $black;

    .rating {
      font-size: 14px;
      margin-left: 10px;

      .icon {
        position: relative;
        width: 20px;
        height: 20px;
        top: 3px;
        fill: $yellow;
        color: $yellow;
        margin: 0 2px;
      }
    }
  }

  .pictures-slider-wrapper {
    #comments-anchor-js {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 5;
      color: #fff;
    }
  }
}

.collapsable-description {
  overflow: hidden;
  transition: height 0.35s ease-out 0s;

  p a {
    text-decoration: underline;
    color: #000;
  }

  &.hide-description {
    position: relative;

    &:not(.fully-expanded) {
      margin-bottom: 20px !important;
    }

    &.fully-expanded::before {
      transition: none;
    }

    &::before {
      content: '';
      width: 100%;
      height: 60%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, #fff);
      display: block;
      position: absolute;
      margin-top: 240px;
      opacity: 0;
      transition: all 0.35s ease-out 0s;
    }
    &.collapsed::before {
      opacity: 1;
      height: 60px;
    }
  }
}

.warning-container {
  display: flex;

  .place-show-warning-description {
    margin-left: 10px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1px;
    word-spacing: 3px;
  }
}

.row-place-similar {
  margin-bottom: 60px;

  .row-place-similar-content {
    display: flex;
    gap: 5px;
    margin: 0 -5px;

    .ct--item-result {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;

      .about {
        flex: 1;

        .title-item-result {
          line-height: 1.1;
        }

        .more {
          margin-bottom: 0;
        }
      }
    }
  }
}
